<template>
    <div>
        <div>
            <b-tabs pills v-model="tab" class="border-2">
                <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
            </b-tabs>
        </div>
        <!--table package -->
        <ek-table
            :items="packages"
            :columns="columns"
            @details="detailsPackage"
            @delete-selected="deletePackage"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>

            <template slot="items.faculties" slot-scope="{ props }">
                <b-button
                    :id="`popover-target-${props.row.originalIndex}`"
                    variant="transperant"
                >
                    مرر للرؤية
                </b-button>
                <b-popover
                    :target="`popover-target-${props.row.originalIndex}`"
                    triggers="hover"
                    placement="right"
                >
                    <span>
                        {{
                            props.row.faculties.length > 0
                                ? props.row.faculties.find((ele) => ele)
                                : "لايوجد"
                        }}
                    </span>
                </b-popover>
            </template>

            <template slot="items.isHidden" slot-scope="{ value }">
                <StatusBadge
                    :options="statusPackage"
                    :value="value"
                ></StatusBadge>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
export default {
    data() {
        return {
            tab: 0,
        };
    },
    components: {
        StatusBadge,
    },
    computed: {
        ...mapState({
            packageList: ({ codes }) => codes.packageList,
            columns: ({ codes }) => codes.columns,
            statusPackage: ({ codes }) => codes.statusPackage,
        }),
        ...mapGetters(["packages"]),
    },
    mounted() {     
        if (this.$route.query.tab !== String(this.tab)) {
            this.$router.replace({ query: { tab: this.tab } });
        }
    },
    watch: {
        tab: function (newTab) {
            if (newTab === 0) {
                this.getPackageList(0);
              
   
                
            } else {
                this.getPackageList(1);

            }
            this.$router.push({ query: { tab: newTab } });

        },
    },
    methods: {
        detailsPackage({ row }) {
            this.$router.push(`/admin/codes/${row.id}`);
        },
        ...mapActions([
            "getPackageList",
            "deletePackage",
            "getGetUnRead",
            "getQuestionBankNames",
           
        ]),
    },
    created() {
        this.getPackageList(0);
        this.getGetUnRead() ;
        this.getQuestionBankNames();
    },

};
</script>

<style></style>
